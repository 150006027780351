import { Team__StaffTypes } from "./collections";
import { TypeEqualsType } from "./internal-utils/typescript-utils";
import { z } from "zod";

export enum AllCollectionNames {
  account = "account",
  accountPrivate = "accountPrivate",
  accountSecret = "accountSecret",
  actionRequest = "actionRequest",
  calendarEntry = "calendarEntry",
  checklistItem = "checklistItem",
  code = "code",
  conversation = "conversation",
  email = "email",
  license = "license",
  licenseHistory = "licenseHistory",
  liveGameCustomEvent = "liveGameCustomEvent",
  liveGameReaction = "liveGameReaction",
  lowPriorityNotificationDetail = "lowPriorityNotificationDetail",
  message = "message",
  player = "player",
  playerBundle = "playerBundle",
  playerBundleNote = "playerBundleNote",
  reportQuery = "reportQuery",
  reportQueryVersions = "reportQueryVersions",
  soccerGame = "soccerGame",
  soccerGameEvent = "soccerGameEvent",
  soccerGameEventBundle = "soccerGameEventBundle",
  soccerStatSnapshot = "soccerStatSnapshot",
  sponsor = "sponsor",
  team = "team",
  teamSettings = "teamSettings",
  coupon = "coupon",
  deferredLink = "deferredLink",
  poll = "poll",
  school = "school",
  evaluationTemplate = "evaluationTemplate",
  evaluation = "evaluation",
  libraryResource = "libraryResource",
  scoreKeepingEvent = "scoreKeepingEvent",
  openOrgEvent = "openOrgEvent",
  openOrgEventRegistration = "openOrgEventRegistration",
  org = "org",
  orgCoupon = "orgCoupon",
  orgCouponHistoryAction = "orgCouponHistoryAction",
  orgRegistrationPackage = "orgRegistrationPackage",
  orgRegistration = "orgRegistration",
  orgRegistrationSuccessMessage = "orgRegistrationSuccessMessage",
  orgRegistrationQuestion = "orgRegistrationQuestion",
  orgRegistrationQuestionGrouping = "orgRegistrationQuestionGrouping",
  orgRegistrationAnswer = "orgRegistrationAnswer",
  orgInvoice = "orgInvoice",
  orgInvoiceHistoryAction = "orgInvoiceHistoryAction",
  orgPayment = "orgPayment",
  orgPaymentRefund = "orgPaymentRefund",
  orgPaymentPlan = "orgPaymentPlan",
  orgPlayerLicense = "orgPlayerLicense",
  orgSeason = "orgSeason",
  orgSettings = "orgSettings",
  orgSecret = "orgSecret",
  orgTeamTag = "orgTeamTag"
}

//Collection tracking information
const IGNORED_COLLECTIONS = [
  AllCollectionNames.lowPriorityNotificationDetail,
  AllCollectionNames.deferredLink,
  AllCollectionNames.soccerGameEvent
] as const;

export const MIRRORED_COLLECTIONS = Object.values(AllCollectionNames).filter(coll => {
  return !IGNORED_COLLECTIONS.includes(coll as any);
});

//NOTE: If adding new derived collections, be sure to update postgres.compute.ts!!
export const COLLECTIONS_WITH_DERIVED_ROWS: AllCollectionNames[] = [
  AllCollectionNames.soccerStatSnapshot,
  AllCollectionNames.soccerGameEventBundle,
  AllCollectionNames.soccerGame
];

export const PATHS_TO_NOT_MIRROR_BY_COLLECTION: { [key in AllCollectionNames]?: string[] } = {
  [AllCollectionNames.message]: ["reactions"],
  [AllCollectionNames.soccerGameEventBundle]: ["compressedJSON"], //This essentially makes it worthless to mirror soccerGameEventBundle. But we keep it for consistency's sake
  [AllCollectionNames.accountPrivate]: [
    "settings.participatingConversationUpdatedAtMS",
    "settings.registrationAndInvoicesLastKeyActionMS",
    "settings.mostRecentParticipatingConversationUpdatedId",
    "userInteractions.numberOfSessions",
    "userInteractions.lastRatingsPrompt"
  ]
};

export function getAuditTableDefinitionSql(table: AllCollectionNames) {
  return `CREATE TABLE IF NOT EXISTS audit_${table}
(
  id serial,
  itemId text,
  beforeItem jsonb,
  afterItem jsonb,
  action text,
  recorded_at timestamp not null,
  PRIMARY KEY (id, recorded_at)
);

SELECT create_hypertable('audit_${table}', 'recorded_at', chunk_time_interval => interval '7 days', if_not_exists => TRUE);

CREATE INDEX IF NOT EXISTS audit_${table}_docid_idx ON audit_${table} (itemId);
CREATE INDEX IF NOT EXISTS audit_${table}_recorded_at_idx ON audit_${table} (recorded_at);

ALTER TABLE audit_${table} SET (
    timescaledb.compress,
    timescaledb.compress_orderby = 'recorded_at DESC'
);

SELECT add_compression_policy('audit_${table}', INTERVAL '30 days', if_not_exists => TRUE);
SELECT add_retention_policy('audit_${table}', INTERVAL '3 years', if_not_exists => TRUE);`;
}

export function getMirrorTableDefinitionSql(table: AllCollectionNames) {
  const lcTable = table.toLowerCase();
  return `
CREATE TABLE IF NOT EXISTS mirror_${lcTable}
  (
    id text PRIMARY KEY,
    item jsonb not null,
    refreshed_at timestamp not null,
    updated_at timestamp GENERATED ALWAYS AS (
        CASE
            WHEN item ->> '__updatedAtMS' IS NULL THEN NULL
            ELSE string_ms_to_timestamp(item ->> '__updatedAtMS')
        END
    ) STORED,
    created_at timestamp GENERATED ALWAYS AS (
        CASE
            WHEN item ->> 'createdAtMS' IS NULL THEN NULL
            ELSE string_ms_to_timestamp(item ->> 'createdAtMS')
        END
    ) STORED,
    item_hash text GENERATED ALWAYS AS (md5((item - '__updatedAtMS' - 'updatedAtMS')::text)) STORED
  );

CREATE INDEX IF NOT EXISTS idx_${lcTable}_refreshed_at ON mirror_${lcTable} (refreshed_at);
CREATE INDEX IF NOT EXISTS idx_${lcTable}_updated_at ON mirror_${lcTable} (updated_at);
CREATE INDEX IF NOT EXISTS idx_${lcTable}_created_at ON mirror_${lcTable} (created_at);
  `;
}

export const MIRROR_AND_AUDIT_JOB_QUEUE = "mirror-and-audit-job-queue";

export type MirrorAndAuditJobData = {
  type: "mirror" | "audit" | "mirrorDerived";
  triggerType: "gcp-listener" | "firestore-lift-hook" | "cron-failsafe";
  collection: AllCollectionNames;
  docId: string;
  __updatedAtMS: number;
  before: object | null;
  after: object | null;
};

export enum RevenueCatEntitlements {
  premiumIndividual = "premium-individual-01",
  premiumFamily = "premium-family-01"
}

//TODO: These collection names are deprecated. Use the AllCollectionNames above
//START DEPRECATED
export const TeamCollectionName = AllCollectionNames.team;
export const ActionRequestCollectionName = AllCollectionNames.actionRequest;
export const LicenseCollectionName = AllCollectionNames.license;
export const LicenseHistoryCollectionName = AllCollectionNames.licenseHistory;
export const PlayerCollectionName = AllCollectionNames.player;
export const PlayerBundleCollectionName = AllCollectionNames.playerBundle;
export const OrgCollectionName = AllCollectionNames.org;
export const MessageCollectionName = AllCollectionNames.message;
export const ConversationCollectionName = AllCollectionNames.conversation;
export const CodeCollectionName = AllCollectionNames.code;
export const CalendarEntryCollectionName = AllCollectionNames.calendarEntry;
export const AccountCollectionName = AllCollectionNames.account;
export const SoccerGameCollectionName = AllCollectionNames.soccerGame;
export const SoccerGameEventCollectionName = AllCollectionNames.soccerGameEvent;
export const SoccerGameEventBundleCollectionName = AllCollectionNames.soccerGameEventBundle;
export const SoccerStatSnapshotCollectionName = AllCollectionNames.soccerStatSnapshot;
export const LowPriorityNotificationDetailCollectionName = AllCollectionNames.lowPriorityNotificationDetail;
export const LiveGameCustomEventCollectionName = AllCollectionNames.liveGameCustomEvent;
export const LiveGameReactionCollectionName = AllCollectionNames.liveGameReaction;
export const ChecklistItemCollectionName = AllCollectionNames.checklistItem;
//END DEPRECATED

export const PROD_FIREBASE = "ollie-app-95ed8";

export enum ExampleStatKeys {
  example1 = "example1",
  example2 = "example2",
  example3 = "example3",
  example4 = "example4",
  example5 = "example5"
}

export const GoogleAddressZod = z.object({
  venue: z.string().optional(),
  fullAddress: z.string().optional(),
  coords: z
    .object({
      lat: z.number(),
      long: z.number()
    })
    .optional(),
  timezone: z.string().optional(),
  placeId: z.string().optional(),
  formattedAddress: z.string().optional(),
  addressComponents: z
    .object({
      address1: z.string().optional(),
      address2: z.string().optional(),
      city: z.string().optional(),
      province: z.string().optional(),
      regionCode: z.string().optional(),
      country: z.string().optional()
    })
    .optional()
});

export type GoogleAddress = z.infer<typeof GoogleAddressZod>;

export enum CALENDAR_ENTRY_TYPES {
  game = "game",
  scrimmage = "scrimmage",
  practice = "practice",
  other = "other"
}

export enum MATCH_TYPES {
  game = "game",
  scrimmage = "scrimmage"
}

export enum GAME_VENUES {
  home = "home",
  away = "away"
}

export enum AUTH_TYPES {
  password = "password",
  facebook = "facebook"
}
export const AUTH_TYPES_YUP = [AUTH_TYPES.facebook, AUTH_TYPES.password];

export enum LICENSE_TYPES {
  account = "account",
  team = "team",
  org = "org"
}

export enum GENDERS {
  boys = "boys",
  girls = "girls",
  mixed = "mixed"
}

export enum CODE_TYPES {
  joinOrgAsStaff = "joinOrgAsStaff",
  joinProfileAsAthlete = "joinProfileAsAthlete",
  joinProfileAsGuardian = "joinProfileAsGuardian",
  joinTeam = "joinTeam",
  navigate = "navigate"
}

export enum TEAM_ROLES {
  staff = "staff",
  guardian = "guardian",
  athlete = "athlete"
}

export enum TEAM_PERMISSIONS {
  // Used as the default or general permission
  basic = "basic",
  participateTeamTeamConversations = "participateTeamTeamConversations",
  participateTeamAthleteConversations = "participateTeamAthleteConversations",
  participateTeamStaffConversations = "participateTeamStaffConversations",
  participateTeamStaffGuardiansConversations = "participateTeamStaffGuardiansConversations",
  participateTeamAllConversations = "participateTeamAllConversations",
  // Used in the firestore rules since some of the permissions cannot easily be locked down on the firestore level (i.e. permissions that both require write to the same document )
  basicStaff = "basicStaff",
  // More specific permissions for staff members (some are used in just the UI)
  manageRoster = "manageRoster",
  manageRolesAndPermissions = "manageRolesAndPermissions",
  manageEvents = "manageEvents",
  recordStats = "recordStats",
  viewIndividualStats = "viewIndividualStats",
  squad_a_staff = "squad_a_staff",
  squad_b_staff = "squad_b_staff",
  squad_c_staff = "squad_c_staff"
}

export const TEAM_PERMISSIONS_ORG_DOESNT_HAVE_BY_DEFAULT: TEAM_PERMISSIONS[] = [
  TEAM_PERMISSIONS.participateTeamAllConversations,
  TEAM_PERMISSIONS.participateTeamAthleteConversations,
  TEAM_PERMISSIONS.participateTeamStaffConversations,
  TEAM_PERMISSIONS.participateTeamTeamConversations,
  TEAM_PERMISSIONS.squad_a_staff,
  TEAM_PERMISSIONS.squad_b_staff,
  TEAM_PERMISSIONS.squad_c_staff
];

// THIS IS MIRRORED IN FIRESTORE RULES. YOU MUST KEEP THESE IN SYNC!!!!!!!!!!!!!
// Not all permissions are granted by roles. See additionalPermissions field on team.accounts
export const TEAM_ROLES_PERMISSIONS_MAPPING: Record<TEAM_ROLES, TEAM_PERMISSIONS[]> = {
  [TEAM_ROLES.athlete]: [
    TEAM_PERMISSIONS.basic,
    TEAM_PERMISSIONS.participateTeamAthleteConversations,
    TEAM_PERMISSIONS.participateTeamTeamConversations,
    TEAM_PERMISSIONS.participateTeamAllConversations
  ],
  [TEAM_ROLES.guardian]: [
    TEAM_PERMISSIONS.basic,
    TEAM_PERMISSIONS.participateTeamAllConversations,
    TEAM_PERMISSIONS.participateTeamStaffGuardiansConversations
  ],
  [TEAM_ROLES.staff]: [
    TEAM_PERMISSIONS.basic,
    TEAM_PERMISSIONS.basicStaff,
    TEAM_PERMISSIONS.participateTeamTeamConversations,
    TEAM_PERMISSIONS.participateTeamAllConversations,
    TEAM_PERMISSIONS.participateTeamStaffConversations,
    TEAM_PERMISSIONS.participateTeamStaffGuardiansConversations
  ]
};

export enum TEAM_SPORT {
  soccer = "soccer",
  americanFootball = "americanFootball",
  basketball = "basketball",
  baseball = "baseball",
  hockey = "hockey",
  softball = "softball",
  lacrosse = "lacrosse",
  volleyball = "volleyball",
  waterPolo = "waterPolo",
  other = "other"
}

export type SerializedFile = {
  name: string;
  type: string;
  size: number;
  base64Content: string;
};

export type TEAM_SPORT_TYPE =
  | TEAM_SPORT.soccer
  | TEAM_SPORT.americanFootball
  | TEAM_SPORT.basketball
  | TEAM_SPORT.hockey
  | TEAM_SPORT.softball
  | TEAM_SPORT.lacrosse
  | TEAM_SPORT.volleyball
  | TEAM_SPORT.waterPolo
  | TEAM_SPORT.other;

export const TEAM_SPORTS_WITH_STATS: { [key in TEAM_SPORT]?: true } = {
  [TEAM_SPORT.soccer]: true
};

export enum TEAM_TYPES {
  club = "club",
  highschool = "highschool",
  college = "college",
  academy = "academy",
  professional = "professional",
  adult = "adult",
  recreation = "recreation",
  tournamentDemo = "tournamentDemo",
  sandbox = "sandbox"
}

export enum CONVERSATION_TYPES {
  accounts = "accounts",
  team = "team",
  org = "org"
}

export enum ORG_CONVERSATION_TYPES {
  all = "all",
  admins = "org-admins",
  coaches = "coaches",
  teamAdmins = "team-admins"
}

export enum ORG_CONVERSATION_ICONS {
  megaphone = "megaphone",
  clipboard = "clipboard",
  whistle = "whistle",
  soccerBall = "soccerBall",
  chat = "chat"
}

export enum TEAM_CONVERSATION_TYPES {
  all = "all",
  alerts = "alerts",
  team = "team",
  staff = "staff",
  staff_guardians = "staff_guardians",
  players = "players",
  squad_a_all = "squad_a_all",
  squad_b_all = "squad_b_all",
  squad_c_all = "squad_c_all",
  squad_a_team = "squad_a_team",
  squad_b_team = "squad_b_team",
  squad_c_team = "squad_c_team",
  squad_a_players = "squad_a_players",
  squad_b_players = "squad_b_players",
  squad_c_players = "squad_c_players"
}

export const CONVERSATION_TYPE_TO_TEAM_ROLES = {
  [TEAM_CONVERSATION_TYPES.all]: {
    [TEAM_ROLES.staff]: true,
    [TEAM_ROLES.guardian]: true,
    [TEAM_ROLES.athlete]: true
  },
  [TEAM_CONVERSATION_TYPES.alerts]: {
    [TEAM_ROLES.staff]: true,
    [TEAM_ROLES.guardian]: true,
    [TEAM_ROLES.athlete]: true
  },
  [TEAM_CONVERSATION_TYPES.team]: {
    [TEAM_ROLES.staff]: true,
    [TEAM_ROLES.athlete]: true
  },
  [TEAM_CONVERSATION_TYPES.staff]: {
    [TEAM_ROLES.staff]: true
  },
  [TEAM_CONVERSATION_TYPES.staff_guardians]: {
    [TEAM_ROLES.staff]: true,
    [TEAM_ROLES.guardian]: true
  },
  [TEAM_CONVERSATION_TYPES.players]: {
    [TEAM_ROLES.athlete]: true
  }
};

export enum RECURRENCE_SCHEMES {
  weekly = "weekly",
  biweekly = "biweekly",
  monthly = "monthly"
}

export const CODE_EXPIRY_MONTHS = 1;

export const ID_CHARACTERS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-";

export const PERMISSIONS_BY_ROLE = {
  readEvents: {
    owner: true,
    admin: true,
    staff: true,
    guardian: true,
    athlete: true
  },
  createEvent: {
    owner: true,
    admin: true,
    staff: true,
    guardian: false,
    athlete: false
  },
  editEvent: {
    owner: true,
    admin: true,
    staff: true,
    guardian: false,
    athlete: false
  },
  viewRoster: {
    owner: true,
    admin: true,
    staff: true,
    guardian: true,
    athlete: true
  },
  viewFans: {
    owner: true,
    admin: true,
    staff: false,
    guardian: false,
    athlete: false
  },

  writeStaff: {
    owner: true,
    admin: true,
    staff: false, //But can edit their own staff account
    guardian: false,
    athlete: false
  },

  writePlayers: {
    owner: true,
    admin: true,
    staff: true,
    guardian: false,
    athlete: false
  },

  editTeam: {
    owner: true,
    admin: true,
    staff: false,
    guardian: false,
    athlete: false
  },
  deleteTeam: {
    owner: true,
    admin: true,
    staff: false,
    guardian: false,
    athlete: false
  },
  recordStats: {
    owner: true,
    admin: true,
    staff: true,
    activityRecorder: true,
    guardian: false,
    athlete: false
  },
  viewTeamStats: {
    owner: true,
    admin: true,
    staff: true,
    guardian: true,
    athlete: true
  },
  viewIndividualStats: {
    owner: true,
    admin: true,
    allStatsViewer: true,
    staff: false,
    guardian: false,
    athlete: false
  },
  manageAttendance: {
    owner: true,
    admin: true,
    staff: true,
    guardian: false,
    athlete: false
  }
};

export type PlayerPosition = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11";

export enum EMOJIS {
  heart = "heart",
  joy = "joy",
  blush = "blush",
  cry = "cry",
  thumbsup = "thumbsup",
  thumbsdown = "thumbsdown",
  muscle = "muscle",
  ok_hand = "ok_hand",
  exploding_head = "exploding_head",
  rage = "rage",
  mask = "mask",
  face_with_head_bandage = "face_with_head_bandage",
  sunglasses = "sunglasses",
  face_with_rolling_eyes = "face_with_rolling_eyes",
  pray = "pray",
  "man-shrugging" = "man-shrugging"
}

export enum STAT_FORMAT_TYPES {
  count = "count",
  ratio = "ratio",
  percent = "percent",
  perHour = "perHour",
  ms = "ms"
}

export enum STAT_RESULT_TYPES {
  numeric = "numeric",
  percent = "percent",
  perHour = "perHour"
}

export enum STAT_ZOOM_LEVELS {
  team = "team",
  allPlayers = "allPlayers",
  player = "player"
}

export enum STAT_CATEGORIES {
  possession = "possession",
  passing = "passing",
  offense = "offense",
  defense = "defense",
  goalkeeper = "goalkeeper",
  workrate = "workrate",
  turnovers = "turnovers",
  infractions = "infractions",
  misc = "misc"
}

export enum STAT_SUBCATEGORIES {
  possession_basic = "possession_basic",
  possession_opportunity = "possession_opportunity",
  passing_basic = "passing_basic",
  offense_basic = "offense_basic",
  defense_basic = "defense_basic",
  goalkeeper_basic = "goalkeeper_basic",
  workrate_basic = "workrate_basic",
  turnovers_basic = "turnovers_basic",
  infractions_basic = "infractions_basic",
  misc_basic = "misc_basic"
}

export const STAT_SUBCATEGORIES_BY_CATEGORY_AND_ZOOM = {
  [STAT_CATEGORIES.possession]: {
    [STAT_ZOOM_LEVELS.team]: {
      [STAT_SUBCATEGORIES.possession_basic]: true,
      [STAT_SUBCATEGORIES.possession_opportunity]: true
    }
  }
};

export function ID_REGEX(prefix: string) {
  return new RegExp(`${prefix}-[${ID_CHARACTERS}]{7,40}$`);
}

export enum ACHIEVEMENTS {
  statsTrained = "statsTrained",
  visitedStatsTab = "visitedStatsTab",
  viewedAddPhotoVideoModalInLGM = "viewedAddPhotoVideoModalInLGM"
}

export enum USER_INTERACTIONS {
  hasUsedAccountTrial = "hasUsedAccountTrial",
  firstLiveGameModeVisit = "firstLiveGameModeVisit",
  firstTimeVoting = "firstTimeVoting",
  lastRatingsPrompt = "lastRatingsPrompt",
  numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder = "numberOfGamesAsHeadCoachSinceWeAddedCoachShareReminder",
  lastSentEmailAboutUpgradingTeam = "lastSentEmailAboutUpgradingTeam",
  hasSeenTrendsInfoModal = "hasSeenTrendsInfoModal",
  numberOfSessions = "numberOfSessions"
}

export const TeamPlanCost = {
  display: "499",
  value: 49900
};

export const TeamAndPlayerProfileCircleSize = 88;
export const MiniAwardAndMVPBadgeSizeForPlayerProfile = 40;
export const TrendsChartHeight = 300;

export enum EVENT_REMINDER_NOTIFICATION_TYPES {
  app = "app",
  email = "email",
  both = "both",
  off = "off"
}

export type EVENT_TYPE_REMINDER_INTERVAL_HOURS = 0 | 1 | 4 | 24 | 48 | 96;

export enum CHAT_ADD_TAB_OPTIONS {
  photos = "photos",
  gifs = "gifs",
  polls = "polls"
}

export enum EVALUATION_ITEM_TYPES {
  rating = "rating",
  comment = "comment"
}

export enum BILLING_TIERS {
  free = "free",
  silver = "silver",
  gold = "gold"
}

export enum LIBRARY_RESOURCE_TYPES {
  text = "text",
  link = "link",
  image = "image",
  video = "video",
  document = "document"
}

export enum ORG_EVENT_ROLE_GROUPS {
  all = "all",
  headCoaches = "headCoaches",
  teamAdmins = "teamAdmins",
  coaches = "coaches",
  teamStaff = "teamStaff",
  clubAdmins = "clubAdmins"
}

export type ORG_EVENT_INVITED_ROLE_OPTIONS = Team__StaffTypes | "all" | "orgAdmin";

export enum ORG_FUNDRAISER_VISIBILITY_STATUS {
  none = "none",
  orgAdmins = "orgAdmins",
  teamAndOrgAdmins = "teamAndOrgAdmins",
  all = "all"
}

export type PaymentStatus = "pending" | "failed" | "success";

export enum JoinTeamErrorCodes {
  selfAthleteAlreadyExistsOnPlayerBundle = "self-athlete-already-exists-on-player-bundle"
}
