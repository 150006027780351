import { OrgId, OrgInvoice, OrgInvoiceParent, PaymentMethod, PaymentMethodSnapshot, PaymentMethodType, PlayerBundleId } from "..";
import { AccountId } from "./Account.schema";
import { OrgInvoiceId } from "./OrgInvoice.schema";

export type OrgPaymentId = string;
type OrgPaymentBase = {
  id: OrgPaymentId;
  orgId: OrgId;
  amountCents: number;
  //This amount is IN ADDITION to amountCents
  lateFeeAmountCents?: number;
  //This amount is IN ADDITION to amountCents
  processingFeeAmountCents?: number;
  createdAtMS: number;
};

export type OrgPaymentCardDetails = {
  cardBrand: string;
  last4: string;
  expMonth: number;
  expYear: number;
};

export enum OrgPaymentType {
  invoiceDefault = "invoiceDefault",
  invoiceFailedPayment = "invoiceFailedPayment",
  invoiceFailedECheckPayment = "invoiceFailedECheckPayment",
  invoiceCredit = "invoiceCredit",
  openOrgEventDefault = "openOrgEventDefault"
}

export type OrgPaymentInvoiceDefault = OrgPaymentBase & {
  type: OrgPaymentType.invoiceDefault;
  status: "succeeded";
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  accountId: AccountId;
  isSettledECheck?: boolean;
  hasPendingChargeback?: boolean;
  nmiPaymentResponseInfo?: NMIPaymentResponseInfo__Success; //Needs to be optional b/c of 0 cent charges (such as from a discount, etc)
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentInvoiceCredit = OrgPaymentBase & {
  type: OrgPaymentType.invoiceCredit;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "succeeded";
  note: string;
  groupingId: string; //Used when admins issue a credit that needs to seamlessly apply across multiple payments.
  appliedByAccountId: AccountId; // usually a club admin
};

export type OrgPaymentInvoiceFailed = OrgPaymentBase & {
  type: OrgPaymentType.invoiceFailedPayment;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "failed";
  failureCode?: CardOrACHCodes | null;
  failureFallbackText?: string | null;
  accountId: AccountId;
  nmiPaymentResponseInfo: NMIPaymentResponseInfo__Failure;
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentInvoiceFailedECheck = OrgPaymentBase & {
  type: OrgPaymentType.invoiceFailedECheckPayment;
  playerBundleId: PlayerBundleId;
  invoiceId: OrgInvoiceId;
  invoiceGroupId: OrgInvoiceId;
  status: "failed";
  accountId: AccountId;
  failureCode?: CardOrACHCodes | null;
  failureFallbackText?: string | null;
  nmiPaymentResponseInfo: NMIPaymentResponseInfo__Success; //The original response from when the payment was first made.
  nmiReturnResponseInfo: {
    created_at_ms?: number;
    transaction_id: string;
    action: {
      amount: string;
      action_type: string;
      date: string;
      success: string;
      ip_address: string;
      source: string;
      api_method: string;
      username: string;
      response_text: string;
      response_code: string;
      processor_response_text: string;
      tap_to_mobile: boolean;
      processor_response_code: string;
      device_license_number: string;
      device_nickname: string;
    };
    check: {
      check_account: null; //NMI doesn't redact enough info on the account # IMO, so set this to null to ensure parts aren't leaked
      check_aba: string;
      check_name: string;
      account_holder_type: string;
      account_type: string;
      sec_code: string;
    };
  };
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentOpenOrgEvent = OrgPaymentBase & {
  type: OrgPaymentType.openOrgEventDefault;
  status: "succeeded";
  nmiPaymentResponseInfo?: NMIPaymentResponseInfo;
};

export type OrgPaymentInvoice =
  | OrgPaymentInvoiceDefault
  | OrgPaymentInvoiceCredit
  | OrgPaymentInvoiceFailed
  | OrgPaymentInvoiceFailedECheck;
export type OrgPayment = OrgPaymentInvoice | OrgPaymentOpenOrgEvent;

export enum OrgPaymentInvoiceCreditApplicationMethod {
  equal = "equal",
  beginning = "beginning",
  end = "end"
}

export const CCFailureCodes = {
  200: "Transaction was declined by processor",
  201: "Do not honor",
  202: "Insufficient funds",
  203: "Over limit",
  204: "Transaction not allowed",
  220: "Incorrect payment information",
  221: "No such card issuer",
  222: "No card number on file with issuer",
  223: "Expired card",
  224: "Invalid expiration date",
  225: "Invalid card security code",
  226: "Invalid PIN",
  240: "Call issuer for further information",
  250: "Pick up card",
  251: "Lost card",
  252: "Stolen card",
  253: "Fraudulent card",
  260: "Declined with further instructions available. (See response text)",
  261: "Declined-Stop all recurring payments",
  262: "Declined-Stop this recurring program",
  263: "Declined-Update cardholder data available",
  264: "Declined-Retry in a few days",
  300: "Transaction was rejected by gateway",
  301: "Rate limit exceeded",
  400: "Transaction error returned by processor",
  410: "Invalid merchant configuration",
  411: "Merchant account is inactive",
  420: "Communication error",
  421: "Communication error with issuer",
  430: "Duplicate transaction at processor",
  440: "Processor format error",
  441: "Invalid transaction information",
  460: "Processor feature not available",
  461: "Unsupported card type"
};

export type NMIResponseCodesType = keyof typeof CCFailureCodes;

export const ACHFailureCodes = {
  R01: "Insufficient Funds",
  R02: "Account Closed",
  R03: "No Bank Account/Unable to Locate Account",
  R04: "Invalid Account Number",
  R05: "Unauthorized Debit to Consumer Account Using Corporate SEC Code",
  R06: "Returned per ODFIs Request",
  R07: "Customer Revoked Authorization",
  R08: "Payment Stopped",
  R09: "Uncollected Funds",
  R10: "Originator not known and/or not authorized to Debit Receiver's Account",
  R11: "Check Truncation Entry Return",
  R12: "Branch Sold to Another DFI",
  R13: "Invalid ACH Routing Number",
  R14: "Representative Payee Deceased or Unable to Continue in That Capacity",
  R15: "Beneficiary or Account Holder Deceased",
  R16: "Account Frozen",
  R17: "File Record Edit Criteria",
  R18: "Improper Effective Entry Date",
  R19: "Amount Field Error",
  R20: "Non-Transaction Account",
  R21: "Invalid Company Identification",
  R22: "Invalid Individual ID Number",
  R23: "Credit Entry Refused by Receiver",
  R24: "Duplicate Entry",
  R25: "Addenda Error",
  R26: "Mandatory Field Error",
  R27: "Trace Number Error",
  R28: "Routing Number Check Digit Error",
  R29: "Corporate Customer Advises Not Authorized",
  R30: "Receiving DFI Not Participant in Check Truncation Program",
  R31: "Permissible Return Entry (CCD and CTX only)",
  R32: "RDFI Non-Settlement",
  R33: "Return of XCK Entry",
  R34: "Limited Participation DFI",
  R35: "Improper Debit Entry",
  R36: "Improper Credit Entry",
  R37: "Source Document Presented for Payment",
  R38: "Stop Payment on Source Document",
  R39: "Improper Source Document",
  R40: "Return of ENR Entry",
  R41: "Invalid Transaction Code",
  R42: "Routing Number/Check Digit Error",
  R43: "Invalid DFI Account Number",
  R44: "Invalid Individual ID Number or Identification Number",
  R45: "Invalid Individual Name/Company Name",
  R46: "Invalid Representative Payee Indicator",
  R47: "Duplicate Enrollment",
  R50: "State Law Affecting RCK Acceptance",
  R51: "Item Related to RCK Entry Is Ineligible or RCK Entry Is Improper",
  R52: "Stop Payment on Item Related to RCK Entry",
  R53: "Item and RCK Entry Presented for Payment",
  R61: "Misrouted Return",
  R62: "Return of Erroneous or Reversing Debit",
  R67: "Duplicate Return",
  R68: "Untimely Return",
  R69: "Field Error",
  R70: "Permissible Return Not Accepted / Not Requested by ODFI",
  R71: "Misrouted Dishonored Return",
  R72: "Untimely Dishonored Return",
  R73: "Timely Original Return",
  R74: "Corrected Return",
  R75: "Original Return Not a Duplicate",
  R76: "No Errors Found",
  R77: "Non-Acceptance of R62",
  R80: "IAT Coding Error",
  R81: "Non-Participant in IAT Program",
  R82: "Invalid Foreign Receiving DFI Identification",
  R83: "Foreign Receiving DFI Unable to Settle",
  R84: "Not Processed by Gateway",
  R85: "Incorrectly Coded Outbound International Payment"
};

//There's a ton of information that's lost when NMI return 300 response codes. This tries to improve the responses
export const GATEWAY_ERROR_CODE_REMAPPING: [RegExp, CardOrACHCodes | Error][] = [
  [/Account number must contain only digits/i, "R04"],
  [/Invalid ABA number/i, "R13"],
  [/Amount exceeds the maximum ticket allowed/i, 203],
  [/Error Processing Transaction\. Please contact customer service\./i, 200],
  [/The account is not set up for this SEC code/i, new Error("Improperly configured merchant or buggy code!")],
  [/Authentication Failed/i, new Error("Improperly configured merchant or buggy code!")],
  [/The cc payment type.+ and.* currency.* is not accepted/i, new Error("Improperly configured merchant or buggy code!")],
  [/The ck payment type.+ and.* currency.* is not accepted/i, new Error("Improperly configured merchant or buggy code!")]
];

export type ACHFailureCodesType = keyof typeof ACHFailureCodes;

export type CardOrACHCodes = ACHFailureCodesType | NMIResponseCodesType;

export function isKeyOfNMIResponseCodes(key: any): key is keyof typeof CCFailureCodes {
  return key.toString() in CCFailureCodes;
}

export type NMIPaymentResponseInfoBase = {
  response: number;
  responseText: string;
  responseCode?: CardOrACHCodes;
};

export type NMIPaymentResponseInfo__Success = NMIPaymentResponseInfoBase & {
  status: "success";
  transactionId: string;
};

export type NMIPaymentResponseInfo__Failure = NMIPaymentResponseInfoBase & {
  status: "failure";
  transactionId?: string;
};

export type NMIPaymentResponseInfo__CatastrophicFailure = NMIPaymentResponseInfoBase & {
  status: "catastrophicError";
  transactionId?: string;
};

export type NMIPaymentResponseInfo =
  | NMIPaymentResponseInfo__Success
  | NMIPaymentResponseInfo__Failure
  | NMIPaymentResponseInfo__CatastrophicFailure;
